import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import swal from "sweetalert";
import config from "../inc/config";
import { getCookie } from "../inc/cookies";

class CreateAppointmentModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      years: Array.from({ length: new Date().getFullYear() - 2005}, (_, index) => new Date().getFullYear() - index),

      vehicleType: [
        {id:1, type: 'Binek Araçlar'},
        {id:2, type: '4x4, 4 teker çekiş, 4WD, AWD'},
        {id:3, type: 'Crossover, MPV, Arazi-SUV-Pikap'},
        {id:4, type: 'Minivan - Panelvan'},
        {id:5, type: 'Ticari Araç'},
        {id:6, type: 'Hafif Ticari Araç'},
        {id:7, type: 'M1 (Transporter-Crafter-Sprinter vb.)'},
      ],

      cities: [],
      cityId: '',
      points: [],
      packages: [],
      availableDates: [],
      appraisalAppointmentId: 0,
      isCompleted: true,
      isTermsAndPrivacyAccepted: false,
      isKVKKAccepted: false,
      modelYear: '',
      availableHours: [],

      postInfo: {
        pointId: 0,
        packageId: 0,
        appointmentDate: '',
        firstname: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        brand: '',
        model: '',
        plate: '',
        vehicleType: 0,
        description: ''
      },
      appointmentDate: '',
      appointmentHour: '',
      combinedDateTime: '',
      
    };
    this.handleCitySelect = this.handleCitySelect.bind(this);
  }

  componentDidMount() {
    this.getAppointments();
    window.scrollTo(0, 0);
  }

  getAppointments() {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + sessionHash },
    };

    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({ isLoading: false });
      }
    };

    fetch(config.restApi + 'appraisal/cities/tr', requestOptions)
    .then((response) => response.json())
    .then((responseData) => {
      switch (responseData.status) {
        case 200:
          {this.setState({ cities: responseData.cities });}
          break;
        default:
          {errorMessages.push(responseData.message);}
          break;
        }
        complete();
    },() => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'appraisal/packages/tr', requestOptions)
    .then((response) => response.json())
    .then((responseData) => {
    switch (responseData.status) {
      case 200:
        {this.setState({ packages: responseData.packages });}
        break;
      default:
        {errorMessages.push(responseData.message);}
        break;
    }
    complete();
    },() => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'appraisalAppointment/availableDates/tr', requestOptions)
    .then((response) => response.json())
    .then((responseData) => {
      switch (responseData.status) {
        case 200:
          {this.setState({ availableDates: responseData.availableDates });}
          break;
        default:
          {errorMessages.push(responseData.message);}
          break;
      }
      complete();
    },() => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  getCityPoints(id) {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + sessionHash },
    };

    fetch(config.restApi + 'appraisal/' + id + '/points/tr/' + this.state.postInfo.vehicleType, requestOptions)
    .then((response) => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({ points: responseData.points });
        }
        break;
        default: {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    },() => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    if (!this.state.isTermsAndPrivacyAccepted || !this.state.isKVKKAccepted) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Uyarı',
        text: 'Lütfen Kullanım Sözleşmesi ve KVKK Aydınlatma Metni kutularını onaylayınız.',
        buttons: 'Tamam'
      });
      return;
    }
    let infoMessage = '';
    if (!this.state.postInfo.vehicleType) {
      infoMessage = 'Araç tipi boş geçilemez !'
    } else if (!this.state.postInfo.pointId) {
      infoMessage = 'Bayi boş geçilemez !'
    } /* else if (!this.state.postInfo.appointmentDate) {
      infoMessage = 'Tarih boş geçilemez !'
    } else if (!this.state.postInfo.appointmentHour) {
      infoMessage = 'Saat boş geçilemez !'
    }  */else if (!this.state.postInfo.packageId) {
      infoMessage = 'Paket boş geçilemez !';
    } else if (!this.state.postInfo.firstname) {
      infoMessage = 'İsim boş geçilemez !'
    } else if (!this.state.postInfo.lastname) {
      infoMessage = 'Soyisim boş geçilemez !'
    } else if (!this.state.postInfo.email) {
      infoMessage = 'Email boş geçilemez !'
    } else if (!this.state.postInfo.phoneNumber) {
      infoMessage = 'Telefon numarası boş geçilemez !'
    } else if (!this.state.postInfo.brand) {
      infoMessage = 'Marka boş geçilemez !'
    } else if (!this.state.postInfo.model) {
      infoMessage = 'Model boş geçilemez !'
    } else if(this.state.modelYear === '') {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Uyarı',
        text: 'Lütfen model yılınızı seçiniz.',
        buttons: 'Tamam'
      });
      return;
    } else if (!this.state.postInfo.plate) {
      infoMessage = 'Plaka boş geçilemez !'
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify(this.state.postInfo)
      };

      fetch(config.restApi + 'appraisalAppointment/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200: {
            this.setState({isCompleted: true, postInfo: ''});

            swal({
              dangerMode: true,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Bilgiler başarıyla eklendi.',
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/appointment-payment?id=' + responseData.appraisalAppointmentId));
          }
          break;
          default: {
            this.setState({isCompleted: true});

            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  saveOnDate = () => {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({pointId: this.state.postInfo.pointId, appointmentDate: this.state.appointmentDate})
    };

    fetch(config.restApi + 'appraisalAppointment/availableHours/tr/', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({isCompleted: true, availableHours: responseData.availableHours});

          /* swal({
            dangerMode: true,
            icon: 'success',
            title: 'Tamamlandı',
            text: 'Bilgiler başarıyla eklendi.',
            buttons: 'Tamam'
          }) */
        }
        break;
        default: {
          this.setState({isCompleted: true});

          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  componentWillUnmount() {}

  handleCitySelect(event) {
    const selectedCityName = event.target.value;
    const selectedCity = this.state.cities.find((city) => city.name === selectedCityName);
    
    if (selectedCity) {
      this.setState({ cityId: selectedCity.code, postInfo: { ...this.state.postInfo, pointId: 0}, appointmentDate: '', appointmentHour: '', points: []}, () => this.getCityPoints(this.state.cityId));
    }
  }

  handlePackageChange = (event) => {
    const selectedPackageName = event.target.value;
    const selectedPackage = this.state.packages.find(packageInfo => packageInfo.name === selectedPackageName);
    this.setState({postInfo: {...this.state.postInfo, packageId: selectedPackage.id}});
  }

  handlePointChange = (event) => {
    const selectedPointsName = event.target.value;
    const selectedPoints = this.state.points.find(pointsInfo => pointsInfo.name === selectedPointsName);
    
    this.setState({postInfo: {...this.state.postInfo, pointId: selectedPoints.id}, appointmentDate: '', appointmentHour: ''});
  }

  handleVehicleTypeChange = (event) => {
    const selectedVehicleType = parseInt(event.target.value);
    this.setState({postInfo: { ...this.state.postInfo, vehicleType: selectedVehicleType, pointId: 0}, appointmentDate: '', appointmentHour: '', cityId: '', points: []});
  };

  reverseFormatDate(formattedDate) {
    const [day, month, year] = formattedDate.split('-');
    const reversedDate = `${year}-${month}-${day}`;
    return reversedDate;
  }

  handleDateChange = (event, callback) => {
    const newDate = event.target.value;
    this.setState({ appointmentDate: newDate, appointmentHour: '' }, () => {
      this.combineDateTime();
      if (callback) {
        callback();
      }
    });
  };

  handleHourChange = (event) => {
    const newHour = event.target.value;
    this.setState({postInfo: { ...this.state.postInfo, packageId: this.state.appointmentHour === '' ? 0 : 1},appointmentHour: newHour }, this.combineDateTime)
  };

  combineDateTime = () => {
    if (this.state.appointmentDate && this.state.appointmentHour) {
      const combinedDateTime = new Date(`${this.state.appointmentDate}T${this.state.appointmentHour}`);
      const appointmentDate = combinedDateTime.toISOString();
      this.setState({postInfo: {...this.state.postInfo, appointmentDate: appointmentDate}, combinedDateTime});
    }
  };

  handlePhoneRegex = (event) => {
    if (/\D+/g.test(event.key)) {
      event.preventDefault();
    }
  }

  handleFullNameRegex = (event) => {
    if (/[^a-zA-ZçÇğĞıİöÖşŞüÜ ]/g.test(event.key)) {
      event.preventDefault();
    }
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="ibox-title text-black d-flex justify-content-between pr-2">
              <h4>YENİ EKSPERTİZ RANDEVU BAŞVURUSU</h4>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-sm-6 b-r mb-0">
                  <div className="d-flex">
                    <i className="fa fa-car icon mr-2 mt-1 text-orange"></i>
                    <h4 className="m-t-none m-b"><strong>ARAÇ TÜRÜ</strong></h4>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-10">
                    {this.state.vehicleType.map((vehicle, index) => (
                    <div className="align-middle" key={index}>
                      <div className="d-flex">
                        <label className="d-flex cursor">
                          <input className="mr-1" type="radio" id={`optionsRadios${vehicle.id}`} name="vehicleType" onChange={this.handleVehicleTypeChange} value={vehicle.id}/>
                          {vehicle.type}
                        </label>
                      </div>
                    </div>
                    ))}
                    </div>
                  </div>
                  <h5>Randevu alınan araç için doğru araç türü seçilmediği takdirde ekspertiz işleminiz sağlanmayacaktır.</h5>
                  {this.state.postInfo.vehicleType !== 0 &&
                  <div>
                    <div className="d-flex">
                      <i className="fa fa-map-marker icon-s2 mr-2 text-orange"></i>
                      <h4 className="m-t-none m-b"><strong>EKSPERTİZ YAPILACAK BAYİ</strong></h4>
                    </div>
                    <div className="form-group">
                      <label>Bayiler</label>
                      <select className="form-control m-b b-r-sm" name="account" onChange={this.handleCitySelect} value={this.state.cityId !== '' ? this.state.cities.find(city => city.code === this.state.cityId).name : 'Şehir Seçiniz'}>
                        <option value="" selected>Şehir Seçiniz</option>
                        {this.state.cities.map((city, index) =>
                          <option key={index} value={city.name}>{city.name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                  }
                  {this.state.cityId !== '' ? (
                    this.state.points.length > 0 ? (
                      <div>
                        <div className="d-flex">
                          <h4 className="m-t-none m-b"><strong>BAYİ SEÇİNİZ</strong></h4>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-10">
                            {this.state.points.map((point, index) =>
                              <div className="align-middle" key={index}>
                                <div className="d-flex">
                                  <label className="d-flex cursor">
                                    <input className="mr-1" id="points" name="appointmentPoint" onChange={(e) => this.handlePointChange(e)} value={point.name} type="radio"/>
                                    {point.name} ({point.address})
                                  </label>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p><strong className="text-red">*</strong> Seçtiğiniz araç türünde bu ilde bayi bulunamadı.</p>
                    )
                  ) : null}
                  {this.state.postInfo.pointId !== 0 &&
                  <div>
                    <div className="d-flex">
                      <i className="fa fa-calendar icon mr-2 mt-1 text-orange"></i>
                      <h4 className="m-t-none m-b"><strong>RANDEVU TARİHİ VE ZAMANI</strong></h4>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <label>Tarih</label>
                        <select className="form-control b-r-sm m-b mr-2" name="account" onChange={(e) => {this.handleDateChange(e, () => {this.saveOnDate();});}} value={this.state.appointmentDate ? this.state.appointmentDate : ''}>
                          <option value="" disabled>Tarih Seçiniz</option>
                          {this.state.availableDates.map((date, index) =>
                            <option key={index} value={date}>{this.reverseFormatDate(date)}</option>
                          )}
                        </select>
                      </div>
                      {this.state.appointmentDate !== '' &&
                      <div className="col-6">
                        <label>Saat</label>
                        <select className="form-control b-r-sm m-b" name="account" onChange={(e) => this.handleHourChange(e)} value={this.state.appointmentHour}>
                          <option value="" selected>Saat Seçiniz</option>
                          {this.state.availableHours.map((time, index) =><option key={index} value={time}>{time}</option>)}
                        </select>
                      </div>
                      }
                    </div>
                  </div>
                  }
                  {this.state.appointmentDate !== '' && this.state.appointmentHour !== '' &&
                  <div>
                    <div className="d-flex">
                      <i className="fa fa-gears icon mr-2 mt-1 text-orange"></i>
                      <h4 className="m-t-none m-b"><strong>EKSPERTİZ PAKET TİPİ</strong></h4>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>Paket Tipi</label>
                        <div className="form-group d-flex">
                          <select className="form-control b-r-sm m-b" onChange={e => this.handlePackageChange(e)}>
                          <option value="" disabled selected>Paket Tipini Seçiniz</option>
                            {this.state.packages.map((packageInfo, index) =><option key={index} value={packageInfo.name}>{packageInfo.name}</option>)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  {this.state.packages.map((packageInfo, index) =>
                  this.state.postInfo.packageId === packageInfo.id && this.state.appointmentDate !== '' && this.state.appointmentHour !== '' && (
                  <div className="ibox-content col-sm-12 b-r-sm" key={index}>
                    <div className="row">
                      <div className="col-6">
                        <p className="d-flex justify-content-between">kaporta {packageInfo.details.kaporta === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">İç Ekspertiz {packageInfo.details.icEkspertiz === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Fren {packageInfo.details.disEkspertiz === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Yanal Kayma {packageInfo.details.yanalKayma === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">OBD Kontrolü {packageInfo.details.obd === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Hasar Sorgu {packageInfo.details.hasarsorgu === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Fotoğraflar {packageInfo.details.fotograflar === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Bir Ay Bin Km Gövde Garanti {packageInfo.details.birAyBinKmGovdeGaranti === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Yanma Odası Kaçak Testi {packageInfo.details.yanmaOdasiKacakTesti === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                      </div>
                      <div className="col-6">
                        <p className="d-flex justify-content-between">Dinamometre {packageInfo.details.dinamometre === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Dış Ekspertiz {packageInfo.details.disEkspertiz === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Süspansiyon {packageInfo.details.suspansiyon === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">HY Belgesi {packageInfo.details.hyBelgesi === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Motor Conta Testi {packageInfo.details.motorcontatesti === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">KM Sorgu {packageInfo.details.kmsorgu === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Yol Yardımı {packageInfo.details.yolYardimi === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                        <p className="d-flex justify-content-between">Bir Ay Bin Km Mekanik Garanti {packageInfo.details.birAyBinKmMekanikGaranti === true ? <i className="fa fa-check d-flex align-items-center text-green"></i>: <i className="fa fa-times d-flex align-items-center text-red"></i>}</p>
                      </div>
                    </div>
                  </div>)
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="d-flex">
                    <i className="fa fa-user icon mr-2 mt-1 text-orange"></i>
                    <h4 className="m-t-none m-b"><strong>RANDEVU SAHİBİ</strong></h4>
                  </div>
                  <form role="form">
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>AD</label>
                        <input className="form-control b-r-sm" onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, firstname: e.target.value}})} onKeyPress={(e) => this.handleFullNameRegex(e)} placeholder="Adınız*" type="text"/>
                      </div>
                      <div className="col-6 form-group">
                        <label>SOYAD</label>
                        <input className="form-control b-r-sm" onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, lastname: e.target.value}})} onKeyPress={(e) => this.handleFullNameRegex(e)} placeholder="Soyadınız*" type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>E-POSTA</label>
                        <input className="form-control b-r-sm" onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, email: e.target.value }})} placeholder="E-posta Adresiniz*" type="text"/>
                      </div>
                      <div className="col-6 form-group">
                        <label>TELEFON</label>
                        <input className="form-control b-r-sm" maxLength={10} onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, phoneNumber: e.target.value}})} onKeyPress={(e) => this.handlePhoneRegex(e)} placeholder="Telefon Numaranız*" type="text"/>
                      </div>
                    </div>
                  </form>
                  <div className="d-flex">
                    <i className="fa fa-car icon mr-2 mt-1 text-orange"></i>
                    <h4 className="m-t-none m-b"><strong>ARAÇ BİLGİLERİ</strong></h4>
                  </div>
                  <form role="form">
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>Marka</label>
                        <input className="form-control b-r-sm" onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, brand: e.target.value }})} placeholder="Marka*" type="text"/>
                      </div>
                      <div className="col-6 form-group">
                        <label>Model</label>
                        <input className="form-control b-r-sm" onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, model: e.target.value }})} placeholder="Model*" type="text"/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-6 form-group mr-3 pl-0">
                        <label>Model Yılı</label>
                        <select className="form-control b-r-sm m-b" style={{ fontSize: '13px' }} name="account" placeholder="Model Yılı*" onChange={(e) => this.setState({modelYear: e.target.value})} value={this.state.modelYear}>
                          <option value="" selected>Model Yılını Seçiniz</option>
                          {this.state.years.map((modelYear, index) => <option key={index} value={modelYear}>{modelYear}</option>)}
                        </select>
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Plaka</label>
                        <input className="form-control b-r-sm" type="text" placeholder="Plaka* (Boşluksuz Yazınız)" onChange={(e) => this.setState({postInfo: { ...this.state.postInfo, plate: e.target.value }})}/>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-12 form-group pl-0 m-0">
                        <label>Açıklama</label>
                        <textarea className="form-control form-input mb-2" onChange={event => this.setState({ postInfo: {...this.state.postInfo, description: event.target.value}})} placeholder="Açıklama" type="text" />
                      </div>
                    </div>
                    <p><strong className="text-red">*</strong> Model yılı 2006'dan düşük araçlara ekspertiz yapılmayacaktır.</p>
                  </form>
                  <div className="ibox-content b-r-sm text-light-gray">
                    <p><strong>Satis Garanti ve Dynobil Ücretsiz Ekspertiz Paketi Hizmeti Nedir?</strong></p>
                    <p>Hizmet dahilinde sisteme yeni dahil olan ve vasıta hizmet alaninda faaliyet gösteren üyelerimize 1 adet ücretsiz kullanım hakkı tanınır. Bazi araç türlerinde ücretsiz hak kullanımı geçerli değildir.</p>
                    <p>Üyeler ücretsiz kullanım hakları bittiğinde ekspertiz paketimizden % 50 indirimli faydalanırlar, tekrar ücretsiz hak tanımlanmaz.</p>
                    <p>Üyeler satisgaranti.com üzerinden kredi kartları ile satın alacakları ücretli ekspertiz hizmeti satınalmalarında %50 indirimli fiyattan faydalanırlar. Bazı araç türlerinde %50 indirimli kullanımı geçerli değildir.</p>
                    <p>Bu hizmet şimdilik sadece Dynobil firmasının belirlemiş olduğu pilot Dynobil Ekspertiz noktalarında geçerlidir.</p>
                    <p><strong>Model yılı 2006 ve üzeri olan araçlara ekspertiz hizmeti verilmektedir.</strong></p>
                    <p>Bu hizmet kampanyası, Satış Garanti ve Dynobil firmasının ortak takdir edecekleri süre boyunca devam edecektir.</p>
                  </div>
                  <div className="col-sm-10 p-0">
                    <div>
                      <label>
                        <input className="cursor mt-2 mr-1" checked={this.state.isTermsAndPrivacyAccepted} onChange={() => this.setState({ isTermsAndPrivacyAccepted: !this.state.isTermsAndPrivacyAccepted })} type="checkbox"/>
                        <a href={`https://www.${config.host}/kullanim-kosullari`} rel="noreferrer" target="_blank"><strong className="text-orange">Kullanım Sözleşmesi</strong></a>'ni okudum, onaylıyorum.
                      </label>
                    </div>
                    <div>
                      <label>
                        <input className="cursor mr-1" checked={this.state.isKVKKAccepted} onChange={() => this.setState({ isKVKKAccepted: !this.state.isKVKKAccepted })} type="checkbox"/>
                        <a href={`https://www.${config.host}/kisisel-verilerin-korunmasi`} rel="noreferrer" target="_blank"><strong className="text-orange">KVKK Aydınlatma Metni</strong></a>'ni okudum. Verilerimin güvenli bir şekilde depolasnmasını onaylıyorum.
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end pr-2 text-black">
                    <button className="btn border btn-darkgray text-white" onClick={this.saveOnClick}><i className="fa fa-arrow-right"></i>Devam</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function CreateAppointment(props) {
  return (
    <CreateAppointmentModule navigate={useNavigate()} searchParams={useSearchParams()[0]}></CreateAppointmentModule>
  );
}

import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import swal from "sweetalert";
import config from "../inc/config";
import { getCookie } from "../inc/cookies";
import { isoToDateTime, priceFormat } from "../inc/helper";

class AppointmentDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      appraisalAppointment: {},

      appoinmentStatus: [
        {id: 0, status: "Randevu için sıraya alındı"},
        {id: 1, status: "Randevu yapılıyor"},
        {id: 2, status: "Randevu başarılı"},
        {id: 3, status: "Randevu başarısız"}
      ],

      paymentStatus: [
        {id: 1, status: "Ödeme Bekleniyor"},
        {id: 2, status: "Ödeme Başarılı"},
        {id: 3, status: "Ödeme Başarısız"},
        {id: 4, status: "ücretsiz"}
      ]
    };
  }

  componentDidMount() {
    this.getMyAppointmentsDetail();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {}

  getMyAppointmentsDetail() {
    let sessionHash = getCookie("sessionHash");

    const requestOptions = {
      method: "GET",
      headers: { Authorization: "Bearer " + sessionHash },
    };

    fetch(config.restApi + 'appraisalAppointment/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then((response) => response.json())
    .then((responseData) => responseData.status === 200 ? this.setState({isLoading: false, appraisalAppointment: responseData.appraisalAppointment})
      : swal({
        dangerMode: true,
        icon: "warning",
        title: "Hay Aksi",
        text: responseData.message,
        buttons: "Tamam",
      }).then(() => this.props.navigate("/")),
      () =>
      swal({
        dangerMode: true,
        icon: "error",
        title: "Bağlantı Hatası",
        text: "Lütfen internet bağlantınızı kontrol ediniz.",
        buttons: "Yeniden Dene",
      }).then(() => this.getMyAppointmentsDetail())
    );
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : '-';
  }

  render() {
    return this.state.isLoading ? (
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col">
          <div className="ibox">
            <div className="d-flex ibox-title text-black justify-content-between pr-2">
              <h4>Başvuru Bilgilerim</h4>
              <div className="d-flex">
                <Link className="btn border btn-darkgray text-white" to={"/appointments"}> <i className="fa fa-arrow-left"></i> Geri Dön </Link>
              </div>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col">
                  <h4 className="text-orange"><strong>RANDEVU</strong></h4>
                  <div className="hr-line-dashed mt-0"></div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Sağlayıcı Adı:</h4>
                        <span>{this.state.appraisalAppointment.providerName ? this.state.appraisalAppointment.providerName : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Randevu No:</h4>
                        <span>{this.state.appraisalAppointment.id ? this.state.appraisalAppointment.id : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Randevu Tarihi:</h4>
                        <span className="text-danger">{isoToDateTime(this.state.appraisalAppointment.appointmentDate)}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Durum:</h4>
                        <span>
                          {this.state.appraisalAppointment.active === 1 ? this.state.appoinmentStatus.find((status) => status.id === this.state.appraisalAppointment.appoinmentStatus)?.status : <span>İptal Edildi</span>}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Açıklama:</h4>
                        <span>
                          {this.state.appraisalAppointment.description ? <span>{this.state.appraisalAppointment.description}</span> : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>RANDEVU SAHİBİ</strong></h4>
                  <div className="hr-line-dashed mt-0"></div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Adı:</h4>
                        <span>{this.state.appraisalAppointment.firstname ? this.state.appraisalAppointment.firstname : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Soyadı:</h4>
                        <span>{this.state.appraisalAppointment.lastname ? this.state.appraisalAppointment.lastname : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">E-mail:</h4>
                        <span>{this.state.appraisalAppointment.email ? this.state.appraisalAppointment.email : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Telefon:</h4>
                        <span>{this.phoneFormat(this.state.appraisalAppointment.phoneNumber)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>ARAÇ BİLGİLERİ</strong></h4>
                  <div className="hr-line-dashed mt-0"></div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Plaka:</h4>
                        <span>{this.state.appraisalAppointment.plate ? this.state.appraisalAppointment.plate : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Marka:</h4>
                        <span>{this.state.appraisalAppointment.brand ? this.state.appraisalAppointment.brand : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Model:</h4>
                        <span>{this.state.appraisalAppointment.model ? this.state.appraisalAppointment.model : "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>EKSPERTİZ BAYİ</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Adı:</h4>
                        <span>{this.state.appraisalAppointment.pointName ? this.state.appraisalAppointment.pointName : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Adres:</h4>
                        <span>{this.state.appraisalAppointment.pointAddress ? this.state.appraisalAppointment.pointAddress : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Telefon:</h4>
                        <span>{this.phoneFormat(this.state.appraisalAppointment.pointNumber)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>EKSPERTİZ PAKETİ</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Paket Adı:</h4>
                        <span>{this.state.appraisalAppointment.packageName ? this.state.appraisalAppointment.packageName : "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Toplam:</h4>
                        <span>{priceFormat(this.state.appraisalAppointment.price)}₺</span>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Açıklama:</h4>
                        <span>{this.state.appraisalAppointment.description ? this.state.appraisalAppointment.description : "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>ÖDEME BİLGİLERİ</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <h4 className="font-bold text-black mb-0">Ödeme Durumu:</h4>
                        {this.state.appraisalAppointment.paymentStatus === 1 || this.state.appraisalAppointment.paymentStatus === 3 ? 
                        (
                          <div>
                            <p className="mb-1">{this.state.paymentStatus.find((status) => status.id === this.state.appraisalAppointment.paymentStatus)?.status}</p>
                            <Link className="btn border btn-darkgray text-white" to={"/appointment-payment?id=" + this.state.appraisalAppointment.id}> <i className="fa fa-money"></i> Ödeme Yap</Link>
                          </div>
                        )
                        :
                        (this.state.paymentStatus.find((status) => status.id === this.state.appraisalAppointment.paymentStatus)?.status)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col">
                  <h4 className="text-orange"><strong>DİĞER BİLGİLER</strong></h4>
                  <div className="hr-line-dashed mt-0" />
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <div className="d-flex flex-column">
                        <span>Bu randevu {isoToDateTime(this.state.appraisalAppointment.addedDate)}tarihinde oluşturulmuştur.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function AppointmentDetail() {
  return (
    <AppointmentDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]}/>
  );
}

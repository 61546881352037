import advertImage from '../assets/img/advertImage.jpg';
import Blogo from '../assets/img/Blogo.png';
import customerServiceImage from '../assets/img/customerServiceImage.png';
import logo from '../assets/img/logo.png';
import mainpageImage from '../assets/img/mainpageImage.png';
import turkiyefinans from '../assets/img/turkiyefinans.png';
import krediturbo from '../assets/img/krediturbo.png';
import senetlisatis from '../assets/img/senetlisatis.png';
import userIcon from '../assets/img/userIcon.png'
import Wlogo from '../assets/img/Wlogo.png';
import welcomeImage from '../assets/img/welcomeImage.png';

const Images = {
  advertImage,
  Blogo,
  customerServiceImage,
  logo,
  mainpageImage,
  turkiyefinans,
  krediturbo,
  senetlisatis,
  userIcon,
  Wlogo,
  welcomeImage
}

export default Images;